/* eslint-disable vue/no-unused-vars */
<template>
   <v-container>

        <v-row align="center" justify="center">
            <v-col cols="12" sm="12" md="12" lg="10" xl="10">
                <v-card elevation="5" style="padding:50px">

                                <v-snackbar
                                        v-model="snackbar"
                                        :color="colorSnackbar"
                                        :timeout="timeout"
                                        >
                                    {{textoSnackbar}}
                                        <template v-slot:action="{ attrs }">
                                            <v-btn
                                            color="white"
                                            text
                                            v-bind="attrs"
                                            @click="snackbar = false"
                                            >
                                            Cerrar
                                            </v-btn>
                                        </template>
                                </v-snackbar>

                                <v-card-text>
                                      <v-row>
                                        <v-col cols="9" md="9" sm="12"></v-col>
                                        <v-col cols="3" md="3" sm="12">
                                          <v-spacer></v-spacer>
                                          <v-btn
                                              color="deep-purple lighten-2"
                                              text
                                              @click="verManual()"
                                          >
                                            Ver el Manual
                                          </v-btn>
                                        </v-col>
                                      </v-row>
                                        <v-row>
                                            <v-col cols="12" md="12" sm="12">
                                                <v-tabs
                                                v-model="tab"
                                                >
                                                 <v-btn
                                                 @click="getData()"
                                                 outlined
                                                    class="mx-2"
                                                    fab
                                                    dark
                                                    small
                                                    color="primary"
                                                    >
                                                    <v-icon dark>
                                                       mdi-refresh
                                                    </v-icon>
                                                    </v-btn>
                                            
                                                    <v-tab key="uno" @click="getData()">Tickets abiertos</v-tab>
                                                    <v-tab key="dos" @click="getMyTickets()">Mis tickets</v-tab>
                                                    <v-tab key="tres" @click="getMyClosedTickets()">Concluidos</v-tab>
                                                </v-tabs>

                                            <v-tabs-items v-model="tab">
                                                <v-tab-item key="uno">
                                                    <v-card flat>
                                                        <v-row>
                                                            <v-col cols="12" md="12" sm="12">

                                                                <v-card elevation="3" style="padding:50px">

                                                                        <v-card-title>
                                                                            Solicitudes de soporte
                                                                            <v-spacer></v-spacer>
                                                                            <v-text-field
                                                                                v-model="search"
                                                                                append-icon="mdi-magnify"
                                                                                label="Buscar"
                                                                                single-line
                                                                                hide-details
                                                                            ></v-text-field>
                                                                        </v-card-title>
                                                                        <v-data-table
                                                                            :headers="headers"
                                                                            :items="resultSet"
                                                                            :items-per-page="7"
                                                                            :search="search"
                                                                            :loading="loading"
                                                                            loading-text="Cargando..."
                                                                            >
                                                                            <template v-slot:item.id="{ item }">

                                                                                <v-btn @click="verSolicitud(item)"
                                                                                            style="color: #fff"
                                                                                            elevation="4"
                                                                                            color="purple">
                                                                                    Ver
                                                                                </v-btn>
                                                                            </template>
                                                                        </v-data-table>
                                                                </v-card>
                                                            </v-col>
                                                        </v-row>
                                                    </v-card>
                                                </v-tab-item>

                                                <v-tab-item key="dos">
                                                    <v-card flat>

                                                        <v-row>
                                                            <v-col cols="12" md="12" sm="12">

                                                                <v-card elevation="3" style="padding:50px">

                                                                        <v-card-title>
                                                                            Mis solicitudes de soporte
                                                                            <v-spacer></v-spacer>
                                                                            <v-text-field
                                                                                v-model="search2"
                                                                                append-icon="mdi-magnify"
                                                                                label="Buscar"
                                                                                single-line
                                                                                hide-details
                                                                            ></v-text-field>
                                                                            </v-card-title>
                                                                            <v-data-table
                                                                            :headers="headers"
                                                                            :items="resultSet2"
                                                                            :items-per-page="7"
                                                                            :search="search2"
                                                                            :loading="loading"
                                                                            loading-text="Cargando..."
                                                                            >
                                                                        <template v-slot:item.id="{ item }">
                                                                            <v-btn @click="verSolicitud(item)"
                                                                                    style="color: #fff"
                                                                                    elevation="4"
                                                                                    small
                                                                                    color="purple">
                                                                                Revisar
                                                                            </v-btn>
                                                                        </template>

                                                                            </v-data-table>

                                                                </v-card>


                                                            </v-col>
                                                        </v-row>

                                                    </v-card>
                                                </v-tab-item>
                                            
                                            <v-tab-item key="tres">
                                                <v-card flat>
                                                    <v-row>
                                                        <v-col cols="12" md="12" sm="12">
                                                            <v-card elevation="3" style="padding:50px">
                                                                <v-card-title>
                                                                    Mis solicitudes de soporte concluidas
                                                                    <v-spacer></v-spacer>
                                                                    <v-text-field
                                                                        v-model="search3"
                                                                        append-icon="mdi-magnify"
                                                                        label="Buscar"
                                                                        single-line
                                                                        hide-details
                                                                    ></v-text-field>
                                                                </v-card-title>
                                                                <v-data-table
                                                                    :headers="headers"
                                                                    :items="resultSet3"
                                                                    :items-per-page="7"
                                                                    :search="search"
                                                                    :loading="loading3"
                                                                     loading-text="Cargando..."
                                                                    >
                                                                    <template v-slot:item.id="{ item }">
                                                                        <v-btn @click="verSolicitud(item)"
                                                                                style="color: #fff"
                                                                                elevation="4"
                                                                                color="purple">
                                                                            Ver
                                                                        </v-btn>
                                                                    </template>
                                                                </v-data-table>                                                                
                                                            </v-card>
                                                        </v-col>
                                                    </v-row>
                                                </v-card>
                                            </v-tab-item>
                                            
                                            </v-tabs-items>
                                            </v-col>
                                        </v-row>
                                   
                                </v-card-text>
                </v-card>
            </v-col>
        </v-row>

  </v-container>
</template>

<script>
export default {
data() {
    return {

         loading: false,
         tab: null,
            timeout:2000,
            snackbar:false,
            textoSnackbar:"",
            colorSnackbar:"",

            resultSet:[],
            search: '',
            headers: [
          {
            text: 'Correo',
            align: 'start',
            sortable: false,
            value: 'email_user_solicitante',
          },
          { text: 'Mensaje', value: 'mensaje' },
          { text: 'Acciones', value: 'id',  align:'center' },
          
        ],
         resultSet2:[],
         search2: '',

        resultSet3:[],
        search3: '',
    }
},//data
    mounted(){
         this.getData();
        },
methods: {
    verManual(){
      this.$router.push(`/manual`);
    },
    normalSnackbar(color, text){
                    this.colorSnackbar  = color;
                    this.textoSnackbar  = text;
                    this.snackbar       = true;
    },
    errorSnackbar(e){
                this.textoSnackbar  = "Ocurrió un error general, favor de comunicarse con su administrador: " + e.name + ': ' + e.message;
                this.colorSnackbar  = "deep-orange darken-3";
                this.snackbar       = true;
    },
    verSolicitud(item) 
    {
        this.$router.push(`/tickets-get/${item.id}`);
    },
    getData(){
            this.loading = true;
            this.$api.get("/api/all-tickets")
            .then((response) => {
                    let { 
                        message
                        ,color
                        ,data
                        ,resultSet
                        } = response.data;
                this.normalSnackbar(color,message);
                this.resultSet = (data == 1) ? resultSet : [];
            })
            .catch( (e) => {
               this.errorSnackbar(e);
            });
            this.loading = false;
        },//getData

        getMyTickets(){                
            this.loading = true;
            this.$api.get("/api/all-myTickets")
            .then((response) => {
                        let { 
                             message
                            ,color
                            ,data
                            ,resultSet
                            } = response.data;
                this.normalSnackbar(color,message);
                this.resultSet2 = (data == 1) ? resultSet : [];

            })
            .catch( (e) => {
               this.errorSnackbar(e);
            });
            this.loading = false;
            
        },//getMyTickets

        getMyClosedTickets(){                
            this.loading = true;
            this.$api.get("/api/all-myClosedTickets") 
            .then((response) => {
                        let { 
                             message
                            ,color
                            ,data
                            ,resultSet
                            } = response.data;
                this.normalSnackbar(color,message);
                this.resultSet3 = (data == 1) ? resultSet : [];
            })
            .catch( (e) => {
               this.errorSnackbar(e);
            });
            this.loading = false;
            
        },//getMyClosedTickets
},

}
</script>

<style scoped>

</style>