import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
//import Dashboard from '../views/Dashboard.vue'
//import Login from '../views/Login.vue'
import Solicitud from "../views/Solicitud.vue";
import Cursos from "../views/Cursos.vue";
import CursosMultiplicados from "../views/CursosMultiplicados.vue";
import CursosMultiplicadosDetalle from "../views/CursosMultiplicados/show.vue";
import GeneracionMasiva from "@/views/GeneracionMasiva/index";
import GeneracionMasivaDetalle from "@/views/GeneracionMasiva/show";
import ArchivadoMasivo from "@/views/Masivo/Archivado";
import EliminadoMasivo from "@/views/Masivo/Eliminado";
import SeguimientoAlumnos from "@/views/Masivo/Alumnos";

import Directores from "@/views/Directores";

import CuentasUsuario from "@/views/CuentasUsuario/CuentasUsuario";
import PanelAdministradores from "@/views/CuentasUsuario/PanelAdministradores";

import SolicitudSostenedor from "@/views/Ticketera/SolicitudSostenedor";
import Parametrizacion from "@/views/Ticketera/Parametrizacion";
import AllTickets from "@/views/Ticketera/All-ticket";
import GetTicket from "@/views/Ticketera/Get-ticket";
import Manual from "@/views/Ticketera/Manual";
import UserAyuda from "@/views/Ticketera/get-userAyuda";
import AllUserAyuda from "@/views/Ticketera/all-userAyuda";

import RickTextEditor from "@/views/PoC/RickTextEditor";
import TinyEditor from "@/views/PoC/TinyEditor.vue";
import SignupForm from "@/views/PoC/SignupForm";
import Flipbook from "@/views/PoC/Flipbook.vue";
import Vue2editor from "@/views/PoC/Vue2editor.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path:"/vueditor2",
    name:"Vueditor2",
    component:Vue2editor
  },

  {
    path:"/TinyEditor",
    name:"TinyEditor",
    component:TinyEditor
  },

  {
    path:"/all-userAyuda",
    name:"Ayuda-usuario",
    component:AllUserAyuda
  },
  {
    path:"/userAyuda/:id",
    name:"Ayuda-usuario-ticket",
    component:UserAyuda
  },
  {
    path: "/Flipbook",
    name: "Flipbook",
    component: Flipbook,
  },
  {
    path: "/manual",
    name:"Manual",
    component: Manual
  },

  {
    path: "/RickTextEditor",
    name: "RickTextEditor",
    component:RickTextEditor,
  },
  
  {
    path: "/SignupForm",
    name: "SignupForm",
    component:SignupForm,
  },

  {
    
    path: "/tickets-get/:id",
    name: "Ver solicitud",
    component: GetTicket,
  },
  {
    path: "/tickets-all",
    name: "Solicitudes Tickets",
    component: AllTickets,
  },
  {
    path: "/parametrizacion",
    name: "Parametrizacion",
    component: Parametrizacion,
  },
  {
    path: "/solicitudSostenedor",
    name: "Solicitud Sostenedor",
    component: SolicitudSostenedor,
  },

  {
    path: "/solicitud",
    name: "Solicitud",
    component: Solicitud,
  },
  {
    path: "/cursos",
    name: "Cursos",
    component: Cursos,
  },
  {
    path: "/cursos-multiplicados",
    name: "CursosMultiplicados",
    component: CursosMultiplicados,
  },
  {
    path: "/cursos-multiplicados/:id",
    name: "CursosMultiplicadosDetalle",
    component: CursosMultiplicadosDetalle,
  },
  {
    path: "/generacion-masiva",
    name: "GeneracionMasiva",
    component: GeneracionMasiva,
  },
  {
    path: "/generacion-masiva/:id",
    name: "GeneracionMasivaDetalle",
    component: GeneracionMasivaDetalle,
  },
  {
    path: "/archivado-masivo",
    name: "ArchivadoMasivo",
    component: ArchivadoMasivo,
  },
  {
    path: "/eliminado-masivo",
    name: "EliminadoMasivo",
    component: EliminadoMasivo,
  },
  {
    path: "/seguimiento-alumnos",
    name: "SeguimientoAlumnos",
    component: SeguimientoAlumnos,
  },
  {
    path: "/directores",
    name: "directores",
    component: Directores,
  },
  {
    path: "/cuentas-usuario",
    name: "CuentasUsuario",
    component: CuentasUsuario,
  },
  {
    path: "/panel-administrador",
    name: "PanelAdministradores",
    component: PanelAdministradores,
 },
  /*{
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
        guest: true
    }
  }*/
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
