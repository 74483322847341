<template>
  
  <v-container>

            <v-btn
                color="deep-purple lighten-2"
                text
                @click="abrirOpciones()"
            >
                Atender
            </v-btn>

    <v-dialog
      v-model="dialog"
      persistent
      max-width="580"
      max-height="200"
    >

      <v-card>
        <v-card-title class="text-h5">
         Mensaje a enviar 
        </v-card-title>
        <v-card-text>
           
        <v-form>
          
            <rich-text-editor 
  
                  @input="onUpdate"
                  @cancel="onCancel"
                  @valid ="onValid"
                  
                  validText="Aceptar" 
                  cancelText="Volver"  
                  cancelColor="warning"
                  validColor="success"
                  
                  buttonsDisplay = [imageButton:false]
                    
                  

                  
                  />


           </v-form>

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="dialog = false"
          >
            Volver
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="checkText()"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
     


</template>
 
<script>

export default {
  name: 'RichTextEditPanel',
  data () {
    return {
        mensajeSoporteTecnico:'',
        dialog:false,
        text: ''
    }
  },
  methods: {

    onValid(){
      console.log("onValid entra");
      console.log(this.text);

    },
    onCancel(){
      console.log("onCancel entra");
    },
    onUpdate(text) 
    {
      this.text = text;
      console.log(this.text);
    },

    abrirOpciones(){
         this.dialog = true;
    },
  }
}
</script>
<style scoped>

</style>