var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":12}},[_c('v-subheader',[_vm._v("Seguimiento de Alumnos")])],1),_c('v-col',{attrs:{"cols":12}},[_c('v-alert',{attrs:{"type":"success","dismissible":""},model:{value:(_vm.alert),callback:function ($$v) {_vm.alert=$$v},expression:"alert"}},[_vm._v(" Cursos copiando, se notificara via correo cuando termine. ")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.cursos,"search":_vm.search,"item-key":"id","loading":_vm.loading,"loading-text":"Cargando..."},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","color":"grey"},on:{"click":function($event){return _vm.getCursos()}}},[_c('v-icon',[_vm._v("mdi-cached")])],1)],1)]},proxy:true},{key:"item.siguiendo",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"disabled":""},model:{value:(item.siguiendo),callback:function ($$v) {_vm.$set(item, "siguiendo", $$v)},expression:"item.siguiendo"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"disabled":item.siguiendo,"color":"orange"},on:{"click":function($event){return _vm.darSeguimiento(item.id)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-book-plus ")])]}}],null,true)},[_c('span',[_vm._v("Dar Seguimiento")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"disabled":!item.spreadsheetUrl,"color":"orange"},on:{"click":function($event){return _vm.abrirSheets(item.spreadsheetUrl)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-google-spreadsheet ")])]}}],null,true)},[_c('span',[_vm._v("Editar Alumnos")])])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.created_at))+" ")]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }