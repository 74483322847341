<template>
  <v-row>
    <v-col :cols="12">
      <v-subheader>Seguimiento de Alumnos</v-subheader>
    </v-col>
    <v-col :cols="12">
      <v-alert v-model="alert" type="success" dismissible>
        Cursos copiando, se notificara via correo cuando termine.
      </v-alert>
      <v-data-table
        :headers="headers"
        :items="cursos"
        :search="search"
        item-key="id"
        class="elevation-1"
        :loading="loading"
        loading-text="Cargando..."
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <!--<v-toolbar-title>Cursos Multiplicados</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>-->
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-btn icon color="grey" @click="getCursos()">
              <v-icon>mdi-cached</v-icon>
            </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:item.siguiendo="{ item }">
          <v-simple-checkbox
            v-model="item.siguiendo"
            disabled
          ></v-simple-checkbox>
      </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="mr-2"
                @click="darSeguimiento(item.id)"
                v-on="on"
                v-bind="attrs"
                :disabled="item.siguiendo"
                color="orange"
              >
                mdi-book-plus
              </v-icon></template
            >
            <span>Dar Seguimiento</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="mr-2"
                @click="abrirSheets(item.spreadsheetUrl)"
                v-on="on"
                v-bind="attrs"
                :disabled="!item.spreadsheetUrl"
                color="orange"
              >
                mdi-google-spreadsheet
              </v-icon></template
            >
            <span>Editar Alumnos</span>
          </v-tooltip>
        </template>
        <template v-slot:item.created_at="{ item }">
          {{ item.created_at | date }}
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "SeguimientoAlumnos",
  data() {
    return {
      search: "",
      alert: false,
      loading: false,
      cursos: [],
      headers: [
        {
          text: "Curso",
          align: "start",
          //sortable: false,
          value: "name",
        },
        { text: "Descripcion", value: "description" },
        { text: "Estado", value: "courseState" },
        { text: "Siguiendo", value: "siguiendo" },
        { text: "Acciones", value: "actions" },
      ],
    };
  },
  computed: {
    /*msg() {
            const selectedRow = this.selected[0];
            return selectedRow ? `${selectedRow.name} ${selectedRow.id}` : "";
        }*/
  },
  mounted() {
    this.getCursos();
  },
  methods: {
    getCursos() {
      this.loading = true;
      this.$api.get("/api/seguimiento-alumnos").then((response) => {
        //console.log(response.data)
        this.cursos = response.data;
        this.loading = false;
      });
    },
    abrirCurso(id) {
      //alert(id)
      //window.open(liga, '_blank')
      this.$router.push({
        name: "CursosMultiplicadosDetalle",
        params: { id: id },
      });
    },
    abrirSheets(liga) {
      window.open(liga, "_blank");
    },
    darSeguimiento(id){
      this.loading = true;
      this.$api.post("/api/seguimiento-alumnos", {
        id: id
      }).then((response) => {
        console.log(response.data)
        //this.cursos = response.data;
        this.loading = false;
      });

    }
  },
};
</script>

<style></style>
