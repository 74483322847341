var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('editor',{attrs:{"api-key":"k8ztloebeslxhfwqvwnd4ajadhqqq5l0o7t3sf46t1q5ongy","apiKey":"k8ztloebeslxhfwqvwnd4ajadhqqq5l0o7t3sf46t1q5ongy","init":{
        height: 500,
        menubar: false,
        plugins: [
          'advlist autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code help wordcount'
        ],
        toolbar:
          'undo redo | formatselect | bold italic backcolor | \
          alignleft aligncenter alignright alignjustify | \
          bullist numlist outdent indent | removeformat | help'
      }}})}
var staticRenderFns = []

export { render, staticRenderFns }