/* eslint-disable vue/no-unused-vars */
<template>
  <flipbook class="flipbook"
    :pages="pages"
      v-slot="flipbook"
       @zoom-start="onZoomStart"
       @zoom-end="onZoomEnd"
    >
    
     <div class="action-bar">

        <button
           class="btn left"
          :class="{ disabled: !flipbook.canFlipLeft }"
          @click="flipbook.flipLeft"
         >
         <span class="mdi mdi-skip-previous"></span>
         </button>

         <button
          class="btn minus"
          :class="{ disabled: !flipbook.canZoomOut }"
          @click="flipbook.zoomOut"
         >
         <span class="mdi mdi-magnify-minus"></span>
         </button>

         <button
          class="btn plus"
          :class="{ disabled: !flipbook.canZoomIn }"
          @click="flipbook.zoomIn"
         >
         <span class="mdi mdi-magnify-plus"></span>
         </button>
         
        <span class="page-num">
          {{ flipbook.page }} - {{ flipbook.numPages }}
        </span>

        <button
           class="btn right"
          :class="{ disabled: !flipbook.canFlipRight }"
          @click="flipbook.flipRight"
         >
         <span class="mdi mdi-skip-next"></span>
         </button>

     </div>

    
    </flipbook>
</template>

<script>
import Flipbook from 'flipbook-vue'
export default {
    components: { Flipbook },
    data() {
        return {
            bucketUrl: 'https://storage.googleapis.com/chile-mineduc/chile-mineduc/manual/',
            pages : [
                null,
                'https://storage.googleapis.com/chile-mineduc/chile-mineduc/manual/1.png',
                'https://storage.googleapis.com/chile-mineduc/chile-mineduc/manual/2.png',
                'https://storage.googleapis.com/chile-mineduc/chile-mineduc/manual/3.png',
                'https://storage.googleapis.com/chile-mineduc/chile-mineduc/manual/4.png',
                'https://storage.googleapis.com/chile-mineduc/chile-mineduc/manual/5.png',
                'https://storage.googleapis.com/chile-mineduc/chile-mineduc/manual/6.png',
                'https://storage.googleapis.com/chile-mineduc/chile-mineduc/manual/7.png',
                'https://storage.googleapis.com/chile-mineduc/chile-mineduc/manual/8.png',
                'https://storage.googleapis.com/chile-mineduc/chile-mineduc/manual/9.png',
                'https://storage.googleapis.com/chile-mineduc/chile-mineduc/manual/10.png',
                'https://storage.googleapis.com/chile-mineduc/chile-mineduc/manual/11.png',
                'https://storage.googleapis.com/chile-mineduc/chile-mineduc/manual/12.png',
                'https://storage.googleapis.com/chile-mineduc/chile-mineduc/manual/13.png',
                'https://storage.googleapis.com/chile-mineduc/chile-mineduc/manual/14.png',
                'https://storage.googleapis.com/chile-mineduc/chile-mineduc/manual/15.png',
                'https://storage.googleapis.com/chile-mineduc/chile-mineduc/manual/16.png',
                'https://storage.googleapis.com/chile-mineduc/chile-mineduc/manual/17.png',
                'https://storage.googleapis.com/chile-mineduc/chile-mineduc/manual/18.png',
                'https://storage.googleapis.com/chile-mineduc/chile-mineduc/manual/19.png',
                'https://storage.googleapis.com/chile-mineduc/chile-mineduc/manual/20.png',
                'https://storage.googleapis.com/chile-mineduc/chile-mineduc/manual/21.png',
                'https://storage.googleapis.com/chile-mineduc/chile-mineduc/manual/22.png',
                'https://storage.googleapis.com/chile-mineduc/chile-mineduc/manual/23.png',
                'https://storage.googleapis.com/chile-mineduc/chile-mineduc/manual/24.png',
                'https://storage.googleapis.com/chile-mineduc/chile-mineduc/manual/25.png',
                'https://storage.googleapis.com/chile-mineduc/chile-mineduc/manual/26.png',
                'https://storage.googleapis.com/chile-mineduc/chile-mineduc/manual/27.png',
                'https://storage.googleapis.com/chile-mineduc/chile-mineduc/manual/28.png',
                'https://storage.googleapis.com/chile-mineduc/chile-mineduc/manual/29.png',
                'https://storage.googleapis.com/chile-mineduc/chile-mineduc/manual/30.png',
                'https://storage.googleapis.com/chile-mineduc/chile-mineduc/manual/31.png',
                
                ]
        }
    },
    mounted() {

    },
}
</script>

<style>

.flipbook {
  width: 90vw;
  height: 90vh;
}

html, body {
  background-color: #333 !important;
  margin: 100;
  padding: 100;
}

#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #333;
  color: #ccc;
  overflow: hidden;
}

a {
  color: inherit;
}

.action-bar {
  width: 100%;
  height: 30px;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.action-bar .btn {
  font-size: 30px;
  color: #999;
}

.action-bar .btn svg {
  bottom: 0;
}

.action-bar .btn:not(:first-child) {
  margin-left: 10px;
}

.has-mouse .action-bar .btn:hover {
  color: #ccc;
  filter: drop-shadow(1px 1px 5px #000);
  cursor: pointer;
}

.action-bar .btn:active {
  filter: none !important;
}

.action-bar .btn.disabled {
  color: #666;
  pointer-events: none;
}

.action-bar .page-num {
  font-size: 12px;
  margin-left: 10px;
}

.flipbook .viewport {
  width: 90vw;
  height: calc(100vh - 50px - 40px);
}

.flipbook .bounding-box {
  box-shadow: 0 0 20px #000;
}

</style>