import Vue from "vue";
import App from "./App.vue";

import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import DateFilter from "./filters/date";
import DateIsoFilter from "./filters/isoDate";

// @ is an alias to /src
import axios from "axios";

const API_BASE_URL = process.env.VUE_APP_API_URL;
Vue.use({
  install(Vue) {
    Vue.prototype.$api = axios.create({
      baseURL: API_BASE_URL,
      withCredentials: true,
    });
    Vue.prototype.$api.interceptors.response.use(
      function(response) {

        let currentRoute = router.currentRoute.path;
        if (currentRoute === "/show-conv-login") 
        {
        //  alert('entra a show-conv-login front');
          let url = process.env.VUE_APP_API_URL;
          Vue.prototype.$api.get("/sanctum/csrf-cookie").then(() => {
            window.location.href =
              url + "/api" + router.currentRoute.fullPath;
          });
        }

        return response;
      },
      function(error) {

        if (error.response.status === 401 ) {

          /*
          Vue.prototype.$api.get("/sanctum/csrf-cookie").then(() => {
            window.location = `${API_BASE_URL}/redirect`;
          });
            */
       //   let url = process.env.VUE_APP_API_URL + `/redirect`;

          let currentRoute = router.currentRoute.path;
         
          if (currentRoute === "/show-conv-login") {
          //  alert('entra a show-conv-login front');
            let url = process.env.VUE_APP_API_URL;

            Vue.prototype.$api.get("/sanctum/csrf-cookie").then(() => {
              window.location.href =
                url + "/api" + router.currentRoute.fullPath;
            });
          }
  

        } 

        if (error.response.status === 419) {
          Vue.prototype.$api.get("/sanctum/csrf-cookie").then(() => {
            window.location = `${API_BASE_URL}/redirect`;
          });
        }
        if (error.response.status === 403) {
          //reempalzar historial y mandar alhome
          window.location = process.env.VUE_APP_URL;
        }
        return Promise.reject(error);
      }
    );
  },
});

Vue.config.productionTip = false;

Vue.filter("date", DateFilter);
Vue.filter("isoDate", DateIsoFilter);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
