var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":12}},[_c('v-subheader',[_vm._v("Archivado de Cursos")])],1),_c('v-col',{attrs:{"cols":12}},[_c('v-alert',{attrs:{"dismissible":"","type":"success"},model:{value:(_vm.alert),callback:function ($$v) {_vm.alert=$$v},expression:"alert"}},[_vm._v(" Archivando Cursos, se notificara via correo cuando termine. ")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.cursos,"loading":_vm.loading,"search":_vm.search,"single-select":_vm.singleSelect,"item-key":"id","loading-text":"Cargando...","show-select":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"color":"white","flat":""}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","hide-details":"","label":"Buscar","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","color":"grey"},on:{"click":function($event){return _vm.getCursos()}}},[_c('v-icon',[_vm._v("mdi-cached")])],1),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.copyItem()}}},'v-btn',attrs,false),[_vm._v("Archivar")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" ¿Esta seguro que desea archivar los siguientes cursos? ")]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-simple-table',{attrs:{"fixed-header":"","height":"300px"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v(" Curso ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Descripcion ")])])]),_c('tbody',_vm._l((_vm.selected),function(item){return _c('tr',{key:item.id},[_c('td',[_vm._v(_vm._s(item.name))]),_c('td',[_vm._v(_vm._s(item.description))])])}),0)]},proxy:true}])})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.close}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.archivarCursos}},[_vm._v("Archivar")])],1)],1)],1)],1)]},proxy:true},{key:"item.alternateLink",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"disabled":!item.alternateLink,"color":"orange"},on:{"click":function($event){return _vm.abrirCurso(item.alternateLink)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-google-classroom ")])]}}],null,true)},[_c('span',[_vm._v("Ver Curso")])])]}},{key:"item.creationTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.creationTime))+" ")]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }