<template>
   <v-container>
    <v-row align="center" justify="center">
      <v-card elevation="5" style="padding:50px">
            <v-card-title class="headline">
              Mis Solicitudes de ayuda
            </v-card-title>
                                  <v-snackbar
                                        v-model="snackbar"
                                        :color="colorSnackbar"
                                        :timeout="timeout"
                                        >
                                    {{textoSnackbar}}
                                        <template v-slot:action="{ attrs }">
                                            <v-btn
                                            color="white"
                                            text
                                            v-bind="attrs"
                                            @click="snackbar = false"
                                            >
                                            Cerrar
                                            </v-btn>
                                        </template>
                                </v-snackbar>
             <v-card-text>
                <v-row>
                  <v-col cols="12" md="12" sm="12">
                      <br>
                      <v-alert
                          dense
                          type="info"
                          prominent
                          >
                            A continuación, se enlistan las solicitudes de AYUDA que usted ha dado de alta, al hacer clic en el botón <b>VER SOLICITUD </b>, se muestran los detalles de la solicitud, así como el seguimiento de esta. <br>
                            <br>
                      </v-alert>
                  </v-col>
                </v-row>
                
                <v-row>
                     <v-col cols="12" md="12" sm="12">
                       <v-card>
                        <v-card-title>
                          <v-text-field
                              v-model="search"
                              append-icon="mdi-magnify"
                              label="Buscar"
                              single-line
                              hide-details
                          ></v-text-field>
                        </v-card-title>


                          <v-data-table
                          :headers="headers"
                          :items="resultSet"
                          :items-per-page="7"
                          :search="search"
                          loading-text="Cargando..."
                          >
                          
                            // eslint-disable-next-line vue/no-unused-vars
                            <template v-slot:item.id="{ item }">

                                      <v-btn style="color: #fff"
                                      elevation="4"
                                      color="purple"
                                      @click="verSolicitud(item.id)"
                                      >Ver
                                      </v-btn>
                          </template>
                          
                          </v-data-table>
                        </v-card>


                     </v-col>

                </v-row>


             </v-card-text>
      </v-card>
    </v-row>

  </v-container>
</template>

<script>
export default {
  data() {
      return {
            loading: false,
          
            timeout:2000,
            snackbar:false,
            textoSnackbar:"",
            colorSnackbar:"",

        resultSet:[],
        search: '',
            headers: [
            {
              text: 'Mensaje',
              align: 'start',
              value: 'mensaje',
            },
            { text: 'Fecha', value: 'fecha' },
            { text: '',   value: 'id',  align:'center'},
          ]
      }
  },//data
  methods:{

    normalSnackbar(color, text){
                    this.colorSnackbar  = color;
                    this.textoSnackbar  = text;
                    this.snackbar       = true;
    },
    verSolicitud(id) {
        this.$router.push(`/userAyuda/${id}`);
    },//verSolicitud

    getData(){
            this.$api.get("/api/user-solicitudes")
            .then((response) => {
                    let { 
                        message
                        ,color
                        ,data
                        ,resultSet
                        } = response.data;
                        console.log(response.data);
                this.normalSnackbar(color,message);
                this.resultSet = (data == 1) ? resultSet : [];
            })
            .catch( (e) => {
              this.resultSet = [];
              // this.errorSnackbar(e);
              console.log(e);
              console.log("ocurrió un error :" + e.getDate())
            });
           
        },//getData
    
  }//methods

  ,mounted(){
     this.getData();
  }
}//export default
</script>

<style>

</style>