var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":12}},[_c('v-subheader',[_vm._v("Generación Masiva")])],1),_c('v-col',{attrs:{"cols":12}},[_c('v-alert',{attrs:{"dismissible":"","type":"success"},model:{value:(_vm.alert),callback:function ($$v) {_vm.alert=$$v},expression:"alert"}},[_vm._v(" Cursos copiando, se notificara via correo cuando termine. ")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.cursos,"loading":_vm.loading,"search":_vm.search,"item-key":"id","loading-text":"Cargando..."},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"color":"white","flat":""}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","hide-details":"","label":"Buscar","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.crearTrabajo()}}},'v-btn',attrs,false),[_vm._v(" Crear ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Crear Generación Masiva")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-text-field',{attrs:{"label":"Nombre Trabajo"},model:{value:(_vm.trabajo.nombre_trabajo),callback:function ($$v) {_vm.$set(_vm.trabajo, "nombre_trabajo", $$v)},expression:"trabajo.nombre_trabajo"}})],1),_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-text-field',{attrs:{"label":"Descripcion"},model:{value:(_vm.trabajo.descripcion),callback:function ($$v) {_vm.$set(_vm.trabajo, "descripcion", $$v)},expression:"trabajo.descripcion"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.close}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.grabarTrabajo}},[_vm._v("Crear")])],1)],1)],1)],1)]},proxy:true},{key:"item.clave",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"color":"orange"},on:{"click":function($event){return _vm.abrirCurso(item.clave)}}},[_vm._v(" mdi-folder ")])]}},{key:"item.terminado",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.terminado ? 'Si' : 'No')+" ")]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.created_at))+" ")]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }