<template>
   <v-container >
     <v-subheader>Seguimiento de mi solicitud</v-subheader>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="12" md="12" lg="10" xl="10">
          <v-card elevation="5" style="padding:50px">

            <v-snackbar
                v-model="snackbar"
                    :color="colorSnackbar"
                    :timeout="timeout"
                >
                {{textoSnackbar}}
                <template v-slot:action="{ attrs }">
                    <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                    >
                    Cerrar
                    </v-btn>
                </template>
            </v-snackbar>

            <v-card-text>
              <v-row>
                      <v-col cols="3" md="3" sm="12">
                      <v-btn v-if="estatus_ticket == 999"
                          color="deep-purple lighten-2"
                          text
                          @click="irAllTickets()"
                      >
                        Mis tickets
                      </v-btn>
                      </v-col>
                      <v-col cols="6" md="6" sm="12"></v-col>
                      <v-col cols="3" md="3" sm="12">
                        <v-btn v-if="estatus_ticket!=1 && this.contestar"
                            color="deep-purple lighten-2"
                            text
                            @click="enviarMensajeSeguimiento()"
                        >
                            Enviar un mensaje
                        </v-btn>
                      </v-col>
                      <p v-if="!this.contestar">Para poder enviar otro mensaje, debe esperar a que el soporte técnico se ponga en contacto primeramente, disculpas</p>
              </v-row>
              <v-divider></v-divider>
              <v-row>
                  <v-col cols="12" md="12" sm="12">
                      <v-card-title>
                        Historial de mensajes
                      </v-card-title>
                      <v-card-text class="estilos_seguimiento">
                          <span v-for="(result, key ) in resultSet"
                                :key="key" >
                          <v-alert v-if="result['RespuestaSeguimientoTicket']"
                              border="left"
                              colored-border
                              color="cyan"
                              elevation="2"
                              >
                              <p class="font-weight-light text-left">
                                <span v-if="result['mensajes']">
                                    <span v-for="(mensaje, index ) in result['mensajes']" :key="index">
                                        <span class="caption"> <i style="color:#95a5a6">{{result['fechasRes'][index]}} - </i></span><span v-html="mensaje"></span>
                                     
                                          <div v-if=" result['adjuntosRes'][index]!='#'">
                                              <a target="_blank"
                                                  :href= "result['adjuntosRes'][index]">
                                                   <v-divider class="my-4"></v-divider>
                                                      <v-img
                                                          class       ="text-right"
                                                          :lazy-src   ="result['adjuntosRes'][index]"
                                                          max-height  ="150"
                                                          max-width   ="250"
                                                          :src        ="result['adjuntosRes'][index]"
                                                      ></v-img>
                                              </a>
                                          </div>
                                          <v-divider class="my-4"></v-divider>

                                    </span>

                                  </span>
                              </p>
                          </v-alert>
                          <v-alert 
                              border="right"
                              colored-border
                              color="#952f81 !important"
                              elevation="2"
                              >
                              <p class="font-italic text-right">
                            <span v-html="result['SeguimientoTicket']"></span> - <span class="caption">  <i style="color:#95a5a6"> {{result['created_at']}} </i></span>
                              </p>
                            <v-divider class="my-4"></v-divider>
                            <div v-if=" result['adjunto_seguimiento']!='#'">
                                <a target="_blank"
                                    :href= "result['adjunto_seguimiento']">
                                        <v-img
                                            class       ="text-right"
                                            :lazy-src   ="result['adjunto_seguimiento']"
                                            max-height  ="150"
                                            max-width   ="250"
                                            :src        ="result['adjunto_seguimiento']"
                                        ></v-img>
                                </a>
                            </div>

                          </v-alert>
                        </span>
                        <v-alert
                              style="background-color:#f1f2f6"
                              border="left"
                              colored-border
                              color="cyan"
                              elevation="2"
                              >
                            <div>
                              <p class="font-weight-light text-left">
                              <span class="caption"> <i style="color:#95a5a6"> {{created_at}} - </i></span>      <span v-html="original_Ticket_text"></span> 
                              </p>
                               <v-divider class="my-4"></v-divider>
                            <div v-if="this.original_ticket_adjunto!='#'">
                                <a target="_blank"
                                    :href=this.original_ticket_adjunto>
                                        <v-img
                                            class       ="text-right"
                                            :lazy-src   ="original_ticket_adjunto"
                                            max-height  ="150"
                                            max-width   ="250"
                                            :src        ="original_ticket_adjunto"
                                        ></v-img>
                                </a>
                            </div>
                              </div>
                          </v-alert>
                      </v-card-text>
                
                  </v-col>
                </v-row>

            </v-card-text>

          </v-card>
        </v-col>
      </v-row>

      



    <v-dialog
      v-model="dialogMensajeSeguimiento"
      persistent
      max-width="490"
    >

      <v-card>
        <v-card-title class="text-h5">
         Mensaje al soporte técnico a manera de seguimiento
        </v-card-title>
        <v-card-text>
           
        <v-form>
   <!--
            <v-textarea
              :counter="500"
              v-model="mensajeSeguimiento"
              :rules="mensajeSeguimientoRules"
              outlined
              required
              label="Mensaje"
              value=""
            ></v-textarea>
-->
            <vue-editor v-model="mensajeSeguimiento" 
                        :editorToolbar="customToolbar" 
                        :placeholder="placeholder"
                        >
            </vue-editor>

            <v-file-input
              prepend-icon="mdi-camera"
              show-size
              truncate-length="16"
           
              label="Archivo adjunto"
              small-chips
              v-model="imagen"
            ></v-file-input>

           </v-form>

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="dialogMensajeSeguimiento = false"
          >
            Volver
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="enviarMensaje()"
          >
           Enviar mensaje
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

   </v-container>
</template>

<script>
import { VueEditor } from "vue2-editor";
export default {
        components: {
            VueEditor
        },
data() {
  return {
      dialog:false,
      timeout:2000,
      snackbar:false,
      textoSnackbar:"",
      colorSnackbar:"",
  
      resultSet:[],
      original_Ticket_text:'',
       original_ticket_adjunto:'',
       created_at:'',
      mensaje_ticket:"",
      estatus_ticket: "",

      contestar:false,

      dialogMensajeSeguimiento:false,
      imagen : [],
      mensajeSeguimiento:"",
      mensajeSeguimientoRules:[
                                      v => !!v || 'Campo requerido',
                              ],
      //vue2editor
      content: "",
      placeholder:"Escriba aquí el mensaje al usuario",
      customToolbar: 
      [
          [{ header: [false, 1, 2, 3, 4, 5, 6] }],
          ["bold", "italic", "underline", "strike"],
          [{ color: [] }],
          [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
          [
              { align: "" },
              { align: "center" },
              { align: "right" },
              { align: "justify" }
          ],
          [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
          ["link", "video"], // ["link", "image", "video"],
          ["clean"]
      ],                              
  }
},//data
mounted () {
      this.showConversation();
},//mounted
methods: {
    enviarMensajeSeguimiento(){
        this.dialogMensajeSeguimiento = true;
    },
    showConversation(){
                const id  = (this.$route.params.id) ? (this.$route.params.id) : 0;
                const url = `/api/show-Conversation/${id}`;

                this.$api.get(url)
                .then( (response) => {
                console.log(response.data);
                this.resultSet = response.data.resultSet;
                this.contestar = response.data.contestar
                this.original_Ticket_text  = response.data.original_Ticket_text;
                this.original_ticket_adjunto  = response.data.original_ticket_adjunto;
                this.created_at = response.data.created_at;
            })
            .catch( (e) => {
                this.errorSnackbar(e);
            });

    },//showConversation


    async enviarMensaje(){
          
        if(this.mensajeSeguimiento == '' || this.mensajeSeguimiento == ' ' || this.mensajeSeguimiento == null )
        {
            this.normalSnackbar("lime darken-2", "El mensaje a enviar NO puede estar vacío");
            this.snackbar       = true;
        return null;
        }
        let formData = new FormData();
          formData.append("id", (this.$route.params.id) ? (this.$route.params.id) : 0  );
          formData.append("imagen", this.imagen);
          formData.append("mensaje", this.mensajeSeguimiento);

        const url = `/api/user-sendMessage-ticket`;
     
        const response  = await this.$api.post(url, formData, 
                      { 
                        headers: {'content-type': 'multipart/form-data'} 
                      }
                    );

        let {message, color}            = response.data;
        this.normalSnackbar(color, message);
        this.mensajeSeguimiento         ='';
        this.dialogMensajeSeguimiento   = false;   
        this.showConversation(); 

    }//enviarMensaje

      ,errorSnackbar(e){
                this.textoSnackbar  = "Ocurrió un error general, favor de comunicarse con su administrador: " + e.name + ': ' + e.message;
                this.colorSnackbar  = "deep-orange darken-3";
                this.snackbar       = true;
      },//errorSnackbar
      normalSnackbar(color, text){
                      this.colorSnackbar  = color;
                      this.textoSnackbar  = text;
                      this.snackbar       = true;
      },//normalSnackbar
      irAllTickets(){
          this.$router.push(`/all-userAyuda`);
      },//irAllTickets
},//methods
}
</script>

<style scoped>
    .estilos_seguimiento
    {   
        border-right: 1px solid #dedede;
        border-left: 1px solid #dedede; 
        padding:25px !important;
    }
</style>