<template>
  <div id="app">
  
    <br><br><br>
  
 <v-btn
                color="deep-purple lighten-2"
                text
                @click="abrirOpciones()"
            >
                Atender
            </v-btn>

 <v-dialog
      v-model="dialog"
      persistent
      max-width="580"
      max-height="200"
    >

      <v-card>
        <v-card-title class="text-h5">
         Mensaje a enviar 
        </v-card-title>
        <v-card-text>
           
        <v-form>

            <vue-editor v-model="content" :editorToolbar="customToolbar" :placeholder="placeholder"></vue-editor>

           </v-form>

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="dialog = false"
          >
            Volver
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="ver()"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>

       </v-dialog>

    <button @click="ver()">ver</button>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";

export default {
  components: {
    VueEditor
  },

  data() {
    return {
     content: "",
     dialog:false,
     placeholder:"mensaje",
     customToolbar: [
     [{ header: [false, 1, 2, 3, 4, 5, 6] }],
     ["bold", "italic", "underline", "strike"],
    [{ color: [] }],
    [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
    [
        { align: "" },
        { align: "center" },
        { align: "right" },
        { align: "justify" }
    ],
      [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
     ["link", "video"], // ["link", "image", "video"],
      ["clean"]
    ]
    };
  }, methods: {
      ver() {
         console.log(this.content); 
         this.dialog = false;
      },
          abrirOpciones(){
         this.dialog = true;
    },
  },
};
</script>


<style scoped>

  #app {
    background-color: white !important;
    color:#333 !important;
  }
  .ql-editor{
    color:black !important;
  }

  #quill-container{
    color:black !important;
  }

</style>