<template>
  <v-row class="d-flex align-start">
    <v-col :cols="12">
      <v-breadcrumbs :items="breadcumbs"></v-breadcrumbs>
    </v-col>
    <v-col :cols="12">
      <v-alert v-model="alert" type="success" dismissible>
        {{ this.mensaje }}, se notificara via correo cuando termine.
      </v-alert>
    </v-col>
    <v-col :cols="12">
      <v-card class="mx-auto">
        <!--<v-img
          class="white--text align-end"
          height="100px"
          src="https://cdn.vuetifyjs.com/images/cards/docks.jpg"
        >-->
        <v-img
          class="white--text align-end"
          height="150px"
          :src="require('@/assets/curso.svg')"
        >
          <v-card-title>{{ this.curso.nombre_trabajo }}</v-card-title>
        </v-img>

        <v-card-subtitle class="pb-0">{{
          this.curso.description
        }}</v-card-subtitle>

        <v-card-text class="text--primary">
          <div>Cursos generados: {{ this.curso.cantidad }}</div>

          <div>Estatus: {{ this.curso.estatus }}</div>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="orange"
            text
            @click="abrirSheets()"
            :disabled="isHeadDisabled"
          >
            Editor de Cursos
          </v-btn>

          <v-btn
            color="orange"
            text
            @click="procesar()"
            :disabled="isHeadDisabled"
          >
            Procesar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
    <v-col :cols="12">
      <v-data-table
        :headers="headers"
        :items="curso.cursos"
        :search="search"
        item-key="id"
        class="elevation-1"
        :loading="loading"
        loading-text="Cargando..."
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>Cursos Generados</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-btn icon color="grey" @click="getCurso()">
              <v-icon>mdi-cached</v-icon>
            </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:item.id="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="mr-2"
                @click="abrirCurso(item.alternateLink)"
                v-on="on"
                v-bind="attrs"
                :disabled="!item.alternateLink"
                color="orange"
              >
                mdi-google-classroom
              </v-icon></template
            >
            <span>Ver Curso</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="mr-2"
                @click="abrirCurso(item.spreadsheetUrl)"
                v-on="on"
                v-bind="attrs"
                :disabled="!item.spreadsheetUrl"
                color="orange"
              >
                mdi-google-spreadsheet
              </v-icon></template
            >
            <span>Editar Alumnos</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="mr-2"
                @click="procesarAlumnos(item.id)"
                v-on="on"
                v-bind="attrs"
                :disabled="!item.spreadsheetUrl"
                color="orange"
              >
                mdi-account-multiple-check
              </v-icon></template
            >
            <span>Inscribir Alumnos</span>
          </v-tooltip>
        </template>
        <template v-slot:item.creationTime="{ item }">
          {{ item.creationTime | date }}
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>
<script>
export default {
  //name:'details',
  data() {
    return {
      mensaje: "",
      alert: false,
      search: "",
      loading: false,
      id: this.$route.params.id,
      curso: {},
      headers: [
        {
          text: "Curso",
          align: "start",
          //sortable: false,
          value: "name",
        },
        { text: "Descripcion", value: "description" },
        { text: "Aula", value: "room" },
        { text: "Creado", value: "creationTime" },
        { text: "Acciones", value: "id" },
      ],
    };
  },
  mounted() {
    this.getCurso();
  },
  methods: {
    getCurso() {
      this.loading = true;
      this.$api.get(`/api/generacion-masiva/${this.id}`).then((response) => {
        //console.log(response.data)
        this.curso = response.data;
        this.loading = false;
      });
    },
    abrirSheets() {
      //alert(id)
      window.open(this.curso.sheets_url, "_blank");
      //this.$router.push({name:'CursosMultiplicadosDetalle',params:{id:id}})
    },
    procesar() {
      this.loading = true;

      this.$api.post(`/api/generacion-masiva/${this.id}`).then((response) => {
        //console.log(response.data)
        //this.curso = response.data

        this.loading = false;
        if (response.data.success) {
          this.mensaje = "Procesando crusos";
          this.alert = true;
          this.hideAlert();
        }
      });
    },
    procesarAlumnos(id) {
      this.loading = true;

      this.$api
        .post(`/api/generacion-masiva/${id}/inscribir`)
        .then((response) => {
          //console.log(response.data)
          //this.curso = response.data
          if (response.data.success) {
            this.mensaje = "Inscribiendo alumnos";
            this.alert = true;
            this.hideAlert();
          }
          this.loading = false;
        });
    },
    abrirCurso(liga) {
      window.open(liga, "_blank");
    },
    hideAlert() {
      setTimeout(() => {
        this.alert = false;
      }, 3000);
    },
  },
  computed: {
    isHeadDisabled() {
      // evaluate whatever you need to determine disabled here...
      return this.loading || !this.curso.sheets_url;
    },
    breadcumbs() {
      return [
        {
          text: "Generación Masiva",
          disabled: false,
          href: "/generacion-masiva",
        },
        {
          text: this.curso.nombre_trabajo,
          disabled: true,
          href: "breadcrumbs_link_2",
        },
      ];
    },
  },
};
</script>
