/* eslint-disable vue/no-unused-components */
/* eslint-disable vue/valid-v-for */
/* eslint-disable vue/no-unused-vars */
<template>
   <v-container >
       <v-subheader></v-subheader>
        <v-row align="center" justify="center">
        
            <v-col cols="12" sm="12" md="12" lg="10" xl="10">

                    <v-card elevation="5" style="padding:50px">
                        <v-snackbar
                            v-model="snackbar"
                                :color="colorSnackbar"
                                :timeout="timeout"
                            >
                            {{textoSnackbar}}
                            <template v-slot:action="{ attrs }">
                                <v-btn
                                color="white"
                                text
                                v-bind="attrs"
                                @click="snackbar = false"
                                >
                                Cerrar
                                </v-btn>
                            </template>
                        </v-snackbar>
                        <v-card-text>
                                <v-row>
                                    <v-col cols="9" md="9" sm="12"></v-col>
                                        <v-col cols="3" md="3" sm="12">
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            color="deep-purple lighten-2"
                                            text
                                            @click="irAllTickets()"
                                        >
                                            Todos los tickets
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" md="12" sm="12">
                                        <v-tabs v-model="tab">
                                            <v-tab key="uno">Básicos</v-tab>
                                            <v-tab key="dos">Técnicos</v-tab>
                                            <v-tab key="tres" v-if="estatus_ticket!=1" @click="showConversation">Seguimiento</v-tab>
                                        </v-tabs>
                                        <v-tabs-items v-model="tab">
                                            <v-tab-item key="uno">
                                                <v-row>
                                                    <v-col cols="12" md="12" sm="12">
                                                        <v-card elevation="3" style="padding:50px">
                                                                    <v-card-title>
                                                                        Detalles básicos de la solicitud de soporte
                                                                        <v-spacer></v-spacer>
                                                                    </v-card-title>
                                                                    <v-card-text>
                                                                        <h3> <b> Nombre del solicitante </b> : <i><u> {{user_name_solicitante}}</u></i> </h3>
                                                                        <h3> <b> Correo del solicitante </b> :  <i><u> {{email_user_solicitante}}</u></i> </h3>
                                                                        <h3> <b> Fecha creación </b> :  <i><u> {{created_at}}</u></i> </h3>

                                                                        <h3> <b> Mensaje </b> :  </h3>
                                                                        <p style="padding:15px; border: 1px solid #dedede">
                                                                            <i> {{mensaje_ticket}}</i>
                                                                        </p>
                                                                            <div v-if="this.url_adjunto!='#'">
                                                                            <a target="_blank"
                                                                              :href=this.url_adjunto>
                                                                                <b> Documento adjunto</b>
                                                                            </a>
                                                                            </div>
                                                                        <br><br>                                
                                                                    </v-card-text> 
                                                        </v-card>
                                                    </v-col>
                                                </v-row> 
                                            </v-tab-item>
                                            <v-tab-item key="dos">

                                    <v-row>
                                    <v-col cols="12" md="12" sm="12">
                                        <v-card elevation="3" style="padding:50px">

                                                    <v-card-title>
                                                        Detalles técnicos de la solicitud de soporte
                                                        <v-spacer></v-spacer>
                                                    </v-card-title>
                                                    <v-card-text>
                                                        <h3> <b> Proyecto </b> : <i><u> {{proyecto_nombre}}</u></i> </h3>
                                                        <h3> <b> Sección </b> : <i><u> {{seccion_modulo_nombre}}  <small>({{seccion_modulo_url}})</small></u></i> </h3>
                                                        <h3> <b> Url </b> : <i><u> {{url_actual}}</u></i> </h3>
                                                        <h3> <b> Plataforma </b> : <i><u> {{platform}}</u></i> </h3>
                                                        <h3> <b> Navegador </b> : <i><u> {{browser}}</u></i> </h3>          
                                                        <br>
                                                        <hr>
                                                        <h3> <b> Patrón del navegador </b> : <small><i><u> {{browser_name_pattern}}</u></i></small> </h3>
                                                        <h3> <b> Parent </b> : <small> <i><u> {{parent}}</u></i></small> </h3>
                                                    </v-card-text>
                                                <hr>
                                              
                                        </v-card>
                                    </v-col>
                                </v-row> 
                                </v-tab-item>
                                <v-tab-item key="tres" v-if="estatus_ticket!=1">

                                    <v-row>
                                    <v-col cols="12" md="12" sm="12">
                                        <v-card elevation="3" style="padding:50px">

                                            <v-card-title>
                                                Mensajes y seguimiento de la solicitud de soporte
                                                <v-spacer></v-spacer>
                                            </v-card-title>
                                             <v-card-actions>
                                                <v-btn v-if="estatus_ticket!=1 && estatus_ticket!=3 && estatus_ticket!=4 && estatus_ticket!=5"
                                                    color="deep-purple lighten-2"
                                                    text
                                                    @click="enviarMensajeSeguimiento()">
                                                    Enviar un mensaje al solicitante
                                                </v-btn>
                                                <v-spacer></v-spacer>
                                                 <v-btn v-if="estatus_ticket!=3 && estatus_ticket!=4 && estatus_ticket!=5"
                                                    color="deep-purple lighten-2"
                                                    text 
                                                    @click="abrirDialogMotivoCierre()"
                                                >
                                                   Cerrar ticket
                                                </v-btn>
                                            </v-card-actions>
                                            <v-card-text class="estilos_seguimiento">
                                <span
                                v-for="(result, key ) in resultSet"
                                :key="key"
                                >
                                    <v-alert v-if="result['RespuestaSeguimientoTicket']"
                                        border="right"
                                        colored-border
                                        color="cyan"
                                        elevation="2"
                                        class="multi-line text-right; "
                                        >               
                         
                                        <p class="font-weight-light text-right">
                                            <span v-if="result['mensajes']">
                                                <span v-for="(mensaje, index ) in result['mensajes']" :key="index">
                                                <span v-html="mensaje"></span>  - <span class="caption"> <i style="color:#95a5a6">{{result['fechasRes'][index]}} </i></span> 
                                              
                                                    <div v-if=" result['adjuntosRes'][index]!='#'">
                                                        <a target="_blank"
                                                            :href= "result['adjuntosRes'][index]">
                                                            <v-divider class="my-4"></v-divider>
                                                                <v-img
                                                                    class       ="text-right"
                                                                    :lazy-src   ="result['adjuntosRes'][index]"
                                                                    max-height  ="150"
                                                                    max-width   ="250"
                                                                    :src        ="result['adjuntosRes'][index]"
                                                                ></v-img>
                                                        </a>
                                                    </div>
                                                    <v-divider class="my-4"></v-divider>

                                                </span>
                                            </span>
                                        </p>
  
                                    </v-alert>

                                    <v-alert
                                        border="left"
                                        colored-border
                                        color="#952f81 !important"
                                        elevation="2"
                                        class="multi-line font-weight-light"
                                        >
                                      <!--   respuesta soporte -->
                                        <p class="text-left">
                                              <span class="caption" >  <i style="color:#95a5a6"> {{result['created_at']}} </i></span>  - <span v-html="result['SeguimientoTicket']"></span> 
                                        </p>   
                                       
                               
                                        <v-divider class="my-4"></v-divider>
                                        <div v-if=" result['adjunto_seguimiento']!='#'">
                                            <a target="_blank"
                                                :href= "result['adjunto_seguimiento']">
                                                    <v-img
                                                        class       ="text-right"
                                                        :lazy-src   ="result['adjunto_seguimiento']"
                                                        max-height  ="150"
                                                        max-width   ="250"
                                                        :src        ="result['adjunto_seguimiento']"
                                                    ></v-img>
                                            </a>
                                        </div>

                                    </v-alert>
                            </span>
                            <v-alert
                              style="background-color:#f1f2f6"
                              border="right"
                              colored-border
                              color="cyan"
                              elevation="2"
                              class="multi-line font-weight-light text-right"
                              >
                             
                           <span v-html="original_Ticket_text"></span> <span class="caption"> <i style="color:#95a5a6"> -{{created_at}} </i></span>
                           <v-divider class="my-4"></v-divider>
                           
                            <div v-if="this.original_ticket_adjunto!='#'">
                                <a target="_blank"
                                    :href=this.original_ticket_adjunto>
                                        <v-img
                                            class       ="text-right"
                                            :lazy-src   ="original_ticket_adjunto"
                                            max-height  ="150"
                                            max-width   ="250"
                                            :src        ="original_ticket_adjunto"
                                        ></v-img>
                                </a>
                            </div>

                        
                          </v-alert>
                                                        
                        </v-card-text>                                              
                                       
                                        </v-card>
                                    </v-col>
                                </v-row> 
                                </v-tab-item>

                                        </v-tabs-items>

                                        <v-card-actions>
                                        <hr>
                                            <v-btn v-if="estatus_ticket==1"
                                                color="deep-purple lighten-2"
                                                text
                                                @click="abrirOpciones()"
                                            >
                                                Atender
                                            </v-btn>
                                        </v-card-actions>        
                                    </v-col>
                                </v-row>
   
                        </v-card-text>
                    </v-card>
            </v-col>  
        </v-row>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="490"
    >

      <v-card>
        <v-card-title class="text-h5">
         Mensaje a enviar al solicitante
        </v-card-title>
        <v-card-text>
           
        <v-form
          >
            <v-textarea
            
              v-model="mensajeSoporteTecnico"
              :rules="mensajeSoporteTecnicoRules"
              outlined
              required
              label="Manda un mensaje al solicitante"
              value=""
            ></v-textarea>
           </v-form>

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="dialog = false"
          >
            Volver
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="atender()"
          >
            Aceptar ticket
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
        

    <v-dialog
      v-model="dialogMensajeSeguimiento"
      persistent
      max-width="490"
    >

      <v-card>
        <v-card-title class="text-h5">
         Mensaje a manera de seguimiento
        </v-card-title>
        <v-card-text>
           
        <v-form
          >
          <!--
            <v-textarea
              v-model="mensajeSeguimiento"
              :rules="mensajeSeguimientoRules"
              outlined
              required
              label="Mensaje de seguimiento al solicitante"
              value=""
            ></v-textarea>
-->
            <vue-editor v-model="mensajeSeguimiento" 
                        :editorToolbar="customToolbar" 
                        :placeholder="placeholder"
                        ></vue-editor>

            <v-file-input
              prepend-icon="mdi-camera"
              show-size
              truncate-length="16"
           
              label="Archivo adjunto"
              small-chips
              v-model="imagen"
            ></v-file-input>

           </v-form>

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="dialogMensajeSeguimiento = false"
          >
            Volver
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="enviarMensaje()"
          >
           Enviar mensaje
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

 <v-dialog
    v-model="dialogMotivoCierre"
    persistent
    max-width="600"
    >
    <v-card>
        <v-card-title class="headline">
        Motivo de Cierre
        </v-card-title>
        <v-card-text>
        <v-radio-group v-model="motivo">
            <v-radio
                label="CONCLUIDO POR PETICION DEL CLIENTE"
                color="red"
                value="3"
            ></v-radio>

            <v-radio
                label="CONCLUIDO POR FALTA DE RESPUESTA POR PARTE DEL CLIENTE"
                color="red"
                value="4"
            ></v-radio>

            <v-radio
                label="CONCLUIDO SATISFACTORIAMENTE"
                color="red"
                value="5"
            ></v-radio>
            </v-radio-group>
            </v-card-text>
        <v-card-actions>
            <v-btn 
            color="green"
            text
            @click="guardarMotivoCierre()"
        >
            Guardar
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
            color="green darken-1"
            text
            @click="dialogMotivoCierre = false"
        >
            Volver
        </v-btn>
        </v-card-actions>
    </v-card>
    </v-dialog>


  </v-container>
</template>

<script>
import { VueEditor } from "vue2-editor";
export default {
      components: {
            VueEditor
        },
data() {
    return { 
            tab: null,
            mensajeSoporteTecnico: '',
            mensajeSoporteTecnicoRules: [
                                            v => !!v || 'Campo requerido',
                                        ],
            dialogMensajeSeguimiento:false,
            imagen : [],
            mensajeSeguimiento:"",
            mensajeSeguimientoRules:[
                                            v => !!v || 'Campo requerido',
                                    ],
            dialog:false,
            timeout:2000,
            snackbar:false,
            textoSnackbar:"",
            colorSnackbar:"",

            original_Ticket_text:'',
            original_ticket_adjunto:'',
            created_at:'',
            mensaje_ticket:"",
            proyecto_nombre:"",
            seccion_modulo_nombre:"",

            seccion_modulo_url:"",
            url_actual:"",
            email_user_solicitante:"",

            dominio_solicitante:"",
            browser_name_pattern:"",
            browser:"",

            parent:"",
            platform:"",
            url_adjunto:"",
            user_name_solicitante:"",

            estatus_ticket: "",
            resultSet:[],

            dialogMotivoCierre:false,
            motivo:null,

            //vue2editor
            content: "",
            placeholder:"Escriba aquí el mensaje al usuario",
            customToolbar: 
            [
                [{ header: [false, 1, 2, 3, 4, 5, 6] }],
                ["bold", "italic", "underline", "strike"],
                [{ color: [] }],
                [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
                [
                    { align: "" },
                    { align: "center" },
                    { align: "right" },
                    { align: "justify" }
                ],
                [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
                ["link", "video"], // ["link", "image", "video"],
                ["clean"]
            ],
    }
},//data
mounted () {
    this.getData();
    //this.showConversation();
},
methods: {
  guardarMotivoCierre(){
        const id  = (this.$route.params.id) ? (this.$route.params.id) : 0;
          
        this.$api.post(`/api/cerrar-ticket`,  {
                    id: id,
                    motivo: this.motivo,
                    })
                    .then( ( response ) => {
                      
                let {message, color} = response.data;
                    this.normalSnackbar(color, message);
                   this.dialogMotivoCierre = false;
                    })
                    .catch( (e) => {
                        this.errorSnackbar(e);
                    });
          
    },
    abrirDialogMotivoCierre(){
        this.dialogMotivoCierre = true;
    },
    errorSnackbar(){
                this.textoSnackbar  = "Ocurrió un error general, favor de comunicarse con su administrador";
                this.colorSnackbar  = "deep-orange darken-3";
                this.snackbar       = true;
    },
    normalSnackbar(color, text){
                    this.colorSnackbar  = color;
                    this.textoSnackbar  = text;
                    this.snackbar       = true;
    },
    showConversation(){

            const id  = (this.$route.params.id) ? (this.$route.params.id) : 0;
            const url = `/api/show-Conversation/${id}`;

            this.$api.get(url)
            .then( (response) => {
            console.log(response.data);
            this.resultSet = response.data.resultSet;
             this.original_Ticket_text  = response.data.original_Ticket_text;
             this.original_ticket_adjunto  = response.data.original_ticket_adjunto;
             this.created_at = response.data.created_at;

        })
        .catch( (e) => {
            this.errorSnackbar(e);
        });

},
    atender(){
            const id  = (this.$route.params.id) ? (this.$route.params.id) : 0;
            if(id == 0){
                 this.normalSnackbar("lime darken-2", "No se tiene referenciado ningún ticket");
                return null;
            }
            if(this.mensajeSoporteTecnico == '' || this.mensajeSoporteTecnico == ' ' || this.mensajeSoporteTecnico == null ){
                  this.normalSnackbar("lime darken-2", "El campo mensaje no puede estar vacío");
                  return null;
            }
          const url = `/api/accept-ticket`;
          this.$api.post(url, {
                    id:      id,
                    mensaje: this.mensajeSoporteTecnico
                    })
          .then( ( response ) => {
                let {message, color} = response.data;
                    this.normalSnackbar(color, message);
                    this.$router.push(`/tickets-all`);
          })
          .catch( (e) => {
            this.errorSnackbar(e);
          });
       this.mensajeSoporteTecnico = "";
    }//atender

    ,irAllTickets(){
        this.$router.push(`/tickets-all`);
    },
    verSolicitud(item) 
    {
        console.log(item);
    },
    abrirOpciones(){
         this.dialog = true;
    },
    enviarMensajeSeguimiento(){
        this.dialogMensajeSeguimiento = true;
    },
    async enviarMensaje(){

        if(this.mensajeSeguimiento == '' || this.mensajeSeguimiento == ' ' || this.mensajeSeguimiento == null ){
            this.normalSnackbar("lime darken-2", "El mensaje a enviar NO puede estar vacío");
            this.snackbar       = true;
            return null;
        }
        let formData = new FormData();
            formData.append("id", (this.$route.params.id) ? (this.$route.params.id) : 0  );
            formData.append("imagen", this.imagen);
            formData.append("mensaje", this.mensajeSeguimiento);

        const response  = await this.$api.post(`/api/sendMessage-ticket`, formData, 
                      { 
                        headers: {'content-type': 'multipart/form-data'} 
                      }
                    );

        let {message, color}            = response.data;
        this.normalSnackbar(color, message);
        this.mensajeSeguimiento         ='';
        this.dialogMensajeSeguimiento   = false;   
        this.showConversation(); 
    }//enviarMensaje

    ,getData(){
            const id  = (this.$route.params.id) ? (this.$route.params.id) : 0;
            const url = `/api/get-ticket/${id}`;
            this.$api.get(url)
            .then( (response) => {
                 console.log(response.data);
                let { 
                      message
                    , color
                    , mensaje_ticket 
                    , created_at

                    , proyecto_nombre 
                    , seccion_modulo_nombre
                    , seccion_modulo_url
                    , url_actual
                    , email_user_solicitante
                    , dominio_solicitante
                    , browser_name_pattern
                    , browser
                    , parent
                    , platform
                    , url_adjunto
                    , user_name_solicitante
                    ,estatus_ticket
                    } = response.data;

                this.normalSnackbar(color, message);

                this.mensaje_ticket         = mensaje_ticket;
                this.created_at             = created_at;

                this.proyecto_nombre        = proyecto_nombre;
                this.seccion_modulo_nombre  = seccion_modulo_nombre;

                this.seccion_modulo_url     = seccion_modulo_url;
                this.url_actual             = url_actual;
                this.email_user_solicitante = email_user_solicitante;

                this.dominio_solicitante    = dominio_solicitante;
                this.browser_name_pattern   = browser_name_pattern;
                this.browser                = browser;

                this.parent                 = parent;
                this.platform               = platform;
                this.url_adjunto            = url_adjunto;
                this.user_name_solicitante  = user_name_solicitante;

                this.estatus_ticket         = estatus_ticket;

            })
            .catch( (e) => {
                    this.errorSnackbar(e);
                });
            }
},

}
</script>

<style scoped>

    .estilos_seguimiento
    {   
        border-right: 1px solid #dedede;
        border-left: 1px solid #dedede; 
        padding:25px !important;
    }

</style>