<template>

 <editor
        api-key="k8ztloebeslxhfwqvwnd4ajadhqqq5l0o7t3sf46t1q5ongy"
        apiKey="k8ztloebeslxhfwqvwnd4ajadhqqq5l0o7t3sf46t1q5ongy"
       :init="{
         height: 500,
         menubar: false,
         plugins: [
           'advlist autolink lists link image charmap print preview anchor',
           'searchreplace visualblocks code fullscreen',
           'insertdatetime media table paste code help wordcount'
         ],
         toolbar:
           'undo redo | formatselect | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | help'
       }"
     />

</template>

<script>
 import Editor from '@tinymce/tinymce-vue'

export default {
components: {
     'editor': Editor
   }
}
</script>

<style>

</style>