<template>
  <v-row>
    <v-col :cols="12">
      <v-subheader>Cursos Multiplicados</v-subheader>
    </v-col>
    <v-col :cols="12">
      <v-alert v-model="alert" type="success" dismissible>
        Cursos copiando, se notificara via correo cuando termine.
      </v-alert>
      <v-data-table
        :headers="headers"
        :items="cursos"
        :search="search"
        item-key="id"
        class="elevation-1"
        :loading="loading"
        loading-text="Cargando..."
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <!--<v-toolbar-title>Cursos Multiplicados</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>-->
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-btn icon color="grey" @click="getCursos()">
              <v-icon>mdi-cached</v-icon>
            </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:item.id="{ item }">
          <v-icon class="mr-2" 
            @click="abrirCurso(item.id)" 
            color="orange">
            mdi-folder
          </v-icon>
        </template>
        <template v-slot:item.created_at="{ item }">
          {{ item.created_at | date }}
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "CursosMultiplicados",
  data() {
    return {
      search: "",
      alert: false,
      loading: false,
      cursos: [],
      headers: [
        {
          text: "Curso",
          align: "start",
          //sortable: false,
          value: "name",
        },
        { text: "Descripcion", value: "description" },
        { text: "Cantidad", value: "cantidad" },
        { text: "Creado", value: "created_at" },
        { text: "Detalle", value: "id" },
      ],
    };
  },
  computed: {
    /*msg() {
            const selectedRow = this.selected[0];
            return selectedRow ? `${selectedRow.name} ${selectedRow.id}` : "";
        }*/
  },
  mounted() {
    this.getCursos();
  },
  methods: {
    getCursos() {
      this.loading = true;
      this.$api.get("/api/alumnos").then((response) => {
        //console.log(response.data)
        this.cursos = response.data;
        this.loading = false;
      });
    },
    abrirCurso(id) {
      //alert(id)
      //window.open(liga, '_blank')
      this.$router.push({
        name: "CursosMultiplicadosDetalle",
        params: { id: id },
      });
    },
  },
};
</script>

<style></style>
