<template>
  <v-row>
    <v-col :cols="12">
      <v-subheader>Generación Masiva</v-subheader>
    </v-col>
    <v-col :cols="12">
      <v-alert v-model="alert" dismissible type="success">
        Cursos copiando, se notificara via correo cuando termine.
      </v-alert>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="cursos"
        :loading="loading"
        :search="search"
        class="elevation-1"
        item-key="id"
        loading-text="Cargando..."
      >
        <template v-slot:top>
          <v-toolbar color="white" flat>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              hide-details
              label="Buscar"
              single-line
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="500px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  class="mb-2"
                  color="primary"
                  dark
                  @click="crearTrabajo()">
                  Crear
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="headline">Crear Generación Masiva</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" md="12" sm="12">
                        <v-text-field
                          v-model="trabajo.nombre_trabajo"
                          label="Nombre Trabajo"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="12" sm="12">
                        <v-text-field
                          v-model="trabajo.descripcion"
                          label="Descripcion"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text @click="close">Cancelar</v-btn>
                  <v-btn color="primary" text @click="grabarTrabajo"
                    >Crear</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.clave="{ item }">
          <v-icon class="mr-2" @click="abrirCurso(item.clave)" color="orange">
            mdi-folder
          </v-icon>
        </template>
        <template v-slot:item.terminado="{ item }">
          {{ item.terminado ? 'Si' : 'No' }}
        </template>
        <template v-slot:item.created_at="{ item }">
          {{ item.created_at | date }}
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "GeneracionMasiva",
  data() {
    return {
      search: "",
      alert: false,
      loading: false,
      dialog: false,
      selected: [],
      headers: [
        {
          text: "Trabajo",
          align: "start",
          //sortable: false,
          value: "nombre_trabajo",
        },
        { text: "Descripcion", value: "descripcion" },
        { text: "Finalizado", value: "terminado" },
        { text: "Creado", value: "created_at" },
        { text: "Acciones", value: "clave", align: "center" },
      ],
      cursos: [],
      trabajo: {},
    };
  },
  computed: {
    msg() {
      const selectedRow = this.selected[0];
      return selectedRow ? `${selectedRow.name} ${selectedRow.id}` : "";
    },
  },
  mounted() {
    this.getCursos();
  },
  methods: {
    getCursos() {
      this.loading = true;
      this.$api.get("/api/generacion-masiva").then((response) => {
        //console.log(response.data)
        this.cursos = response.data;
        this.loading = false;
      });
    },
    abrirCurso(id) {
      //alert(id)
      //window.open(liga, '_blank')
      this.$router.push({
        name: "GeneracionMasivaDetalle",
        params: { id: id },
      });
    },
    close() {
      this.dialog = false;
      this.selected = [];
      this.cantidad = 0;
      this.$nextTick(() => {
        this.trabajo = Object.assign({});
        //this.editedIndex = -1
      });
    },
    crearTrabajo() {
        //Object.assign(this.copiedItem, this.selected[0]);
        this.dialog = true;
    },
    grabarTrabajo() {
      this.$api
        .post("/api/generacion-masiva", {
          nombre_trabajo: this.trabajo.nombre_trabajo,
          descripcion: this.trabajo.descripcion,
        })
        .then((response) => {
            this.alert = true;
            this.hideAlert();
            this.dialog = false;
            this.$nextTick(() => {
              this.trabajo = Object.assign({});
              //this.editedIndex = -1
            });
            this.abrirCurso(response.data.clave);
        });
    },
    hideAlert() {
      setTimeout(() => {
        this.alert = false;
      }, 3000);
    },
  },
};
</script>

<style></style>
