<template >
  <v-app id="inspire">
    <div v-if="!maintenance_mode">
      <v-navigation-drawer v-model="drawer" app clipped>
        <v-list dense>
          <v-list-item v-for="item in menu" :key="item.text" :to="item.link" link>
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>

      <v-app-bar app clipped-left color="primary" dense dark>
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <!--<v-icon
          class="mx-4"
          large
        >
          mdi-school
        </v-icon>-->
        <img class="mr-3" :src="require('@/assets/logo.svg')" height="47"/>
        <!--<v-img src="@/assets/logo.svg" height="36">
              </v-img>
        <v-toolbar-title class="mr-12 align-center">
          <span class="title">EstudioApp</span>
        </v-toolbar-title>-->
        <!--<v-spacer></v-spacer>
        <v-row
          align="center"
          style="max-width: 650px"
        >
          <v-text-field
            :append-icon-cb="() => {}"
            placeholder="Search..."
            single-line
            append-icon="mdi-magnify"
            color="white"
            hide-details
          ></v-text-field>
        </v-row>-->
        <v-spacer></v-spacer>
        <v-btn icon large>
          <v-avatar size="32px" item>
            <v-img
                v-bind:src="this.$store.getters.usuario.avatar"
                alt="this.$store.getters.usuario.name"
            ></v-img>
          </v-avatar>
        </v-btn>
        <v-menu :close-on-content-click="false" :nudge-width="200" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon dark v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-card>
            <v-list>
              <v-list-item>
                <v-list-item-avatar>
                  <img
                      v-bind:src="this.$store.getters.usuario.avatar"
                      v-bind:alt="this.$store.getters.usuario.name"
                  />
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>{{
                      this.$store.getters.usuario.name
                    }}
                  </v-list-item-title>
                  <v-list-item-subtitle>{{
                      this.$store.getters.usuario.email
                    }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn text @click="logout">Salir</v-btn>
              <!--<v-btn color="primary" text @click="menu = false">Save</v-btn>-->
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-app-bar>

      <v-main class="grey lighten-5">
        <v-container >
          <!--<v-row
            justify="center"
            align="center"
          >
            <v-col class="shrink">
              <v-tooltip right>
                <template v-slot:activator="{ on }">
                  <v-btn
                    :href="source"
                    icon
                    large
                    target="_blank"
                    v-on="on"
                  >
                    <v-icon large>mdi-code-tags</v-icon>
                  </v-btn>
                </template>
                <span>Source</span>
              </v-tooltip>
            </v-col>
          </v-row>-->
          <router-view/>
        </v-container>
      </v-main>
      <v-footer color="primary" app>
      <span class="white--text">
        <a href="https://www.nive.la" target="blank" class="white--text"
        >Nivel A</a
        >
        &copy; {{ new Date().getFullYear() }}
      </span>
      </v-footer>
    </div>
    <div v-else>
      <div style="background-color:#952f81;width: 100%; height: 100%; display: flex; position: absolute;">
        <div align="center" style="text-align: center; margin: auto;;color:#f9d94c;">
          <h1 class="text-center">En mantenimiento, ¡volvemos pronto!</h1>
          <br>
          <img style="width:200px;height:200px;" src="img/ayuda-icon.svg" alt="">
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
//import HelloWorld from './components/HelloWorld';
//import Home from './views/Home';

export default {
  name: "App",
  components: {
    //Home,
  },
  props: {
    source: String,
  },
  data: () => ({
    maintenance_mode: false,
    drawer: null,
    /*menu: [*/
    /*{ icon: "mdi-account-circle-outline", text: "Home", link: "/" },*/
    /*{ icon: "mdi-history", text: "About", link: "/about" },
        { icon: "mdi-history", text: "Dashboard", link: "/dashboard" },*/
    /*{ icon: "mdi-school", text: "Cursos Prototipos", link: "/cursos" },
      {
        icon: "mdi-school",
        text: "Cursos Multiplicados",
        link: "/cursos-multiplicados",
      },*/
    /*],*/
    //menu: [],
  }),
  computed: {
    menu: {
      get() {
        return this.$store.getters.usuario.menu;
      },
    },
  },
  beforeCreate() {
    this.$store.dispatch("fetchUsuario");
  },
  created() {
    this.$vuetify.theme.dark = false;
    /*this.$store.dispatch("fetchUsuario").then(() => {
      console.log("Got some data, now lets show something in this component ");
      this.menu = this.$store.getters.usuario.menu;
    });*/
    //this.menu = this.$store.getters.usuario.menu;
  },
  mounted() {
    this.getMaintenanceMode()
    //this.$store.dispatch('fetchUsuario')
  },
  methods: {
    getMaintenanceMode() {
      this.$api.get('/api/maintenance-mode').then(res => {
        this.maintenance_mode = res.data.maintenance_mode
      })
    },
    logout() {
      this.$api.post("/logout").then(() => {
        //this.$router.push({ name: 'Home'})
        window.location =
            "https://www.google.com/accounts/Logout?continue=https://appengine.google.com/_ah/logout?continue=" +
            process.env.VUE_APP_API_URL +
            "/redirect";
      });
    },
  },
};
</script>
